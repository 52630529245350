import React from "react"
//import { Link } from "gatsby"

import Layout from "../components/bs/layout"
//import Image from "../components/image"
import SEO from "../components/bs/seo"

import MainImg from "../images/main_bg.jpg"

import shape1 from "../images/dec/1.png"
import shape2 from "../images/dec/2.png"
import shape3 from "../images/dec/3.png"
import shape4 from "../images/dec/4.png"
import shape5 from "../images/dec/5.png"
import shape6 from "../images/dec/6.png"
import shape7 from "../images/dec/7.png"
import shape8 from "../images/dec/8.png"

const Inspiracija = () => (
  <Layout>
    <SEO
      title="IMMMUNO NATURA - INSPIRACIJA IZ MUDROSTI PRIRODE"
      description="Pronalazimo i stavljamo Vam na raspolaganje univerzalne darove iz riznica prirode u vidu IMMMUNO NATURA dodataka prehrani i kozmetike."
    />

    <section
      id="_heroPage"
      className="hero is-large _inspiracija"
      style={{ backgroundImage: `url(${MainImg})` }}
    >
      <div id="bgHero">
        <span className="shape1">
          <img src={shape1} alt="Shape 1" />
        </span>
        <span className="shape2">
          <img src={shape2} alt="Shape 2" />
        </span>
        <span className="shape3">
          <img src={shape3} alt="Shape 3" />
        </span>
        <span className="shape4">
          <img src={shape4} alt="Shape 4" />
        </span>
        <span className="shape5">
          <img src={shape5} alt="Shape 5" />
        </span>
        <span className="shape6">
          <img src={shape6} alt="Shape 6" />
        </span>
        <span className="shape7">
          <img src={shape7} alt="Shape 7" />
        </span>
        <span className="shape8">
          <img src={shape2} alt="Shape 8" />
        </span>
      </div>
      <div className="hero-body">
        <div className="container">
          <div className="title">INSPIRACIJA</div>
        </div>
      </div>
    </section>

    <span className="_line" />

    <section id="_pageContent_other" className="section">
      <span className="_dec2">
        <img src={shape8} alt="Dec 2" />
      </span>
      <div className="container">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <div className="columns">
              <div className="column _headline">
                <h2 className="subtitle is-4">
                  "Pogledaj duboko u prirodu i onda češ sve bolje razumjeti."
                  Albert Ajnštajn
                </h2>
                <p className="content">
                  Svaki čovjek je poseban i jedinstven kako sa svojim izgledom
                  tako i sa hormonalnim sustavom, imunitetom, emocionalnim,
                  karakternim i biohemijskim karakteristikama. Shodno tome svaki
                  čovjek razvija različite sklonosti i reaguje na sebi svojstven
                  način na poticaje iz svoje okoline. Iz tog razloga isto i pri
                  odabiru odgovarajuće ishrane, kozmetike, dodataka ishrani,
                  dijetetskih proizvoda i sl. treba uzeti u obzir da svaki
                  preparat ne odgovara svakom metabolizmu kao i da svaki
                  organizam ne reaguje isto na jedan te isti preparat. Jedna
                  vrsta hrane ili preparata za jednu osobu može biti blagotvorna
                  dok na drugu ne mora imati nikakav efekat ili joj čak može i
                  naštetiti.
                </p>
                <p className="content">
                  Rijetke supstance iz prirode imaju široku i univerzalnu
                  primjenu koja važi za većinu ljudi. Neke od njih su poznate
                  jer su već hiljadama godina u tradicionalnoj upotrebi, te su
                  opravdali povjerenje proizvoda sa blagotvornim dejstvima ili
                  dodataka ishrani koji obiluju visokokvalitetnim esencijalnim
                  komponentama poput proteina, aminokiselina, nezasićenih masnih
                  kiselina, vitamina, minerala, antioksidanasa, fenolnih
                  komponenata i sl. Greenwells se trudi da vam pronađe i pruži
                  najbolje i najuniverzalnije darove iz riznica prirode koji
                  imaju svoju potporu kako u dugoj tradiciji upotrebe tako i u
                  modernoj nauci. Ipak na svakom čovjeku je da prepozna stil
                  života, hranu i preparate koji mu najbolje odgovaraju, koji mu
                  daju energiju i pomažu pri očuvanju zdravlja.
                </p>
                <p className="content">
                  <strong>
                    Postojan kvalitet proizvoda, težište na prirodnom sastavu i
                    vaše zadovoljstvo su primarni cilj stručnog tima
                    Greenwells-a. Stavljamo Vam na raspolaganje ekskluzivnu
                    selekciju najboljih proizvoda sa težištem na prirodnom
                    izvoru supstanci, blagotvornim efektima i dopuni osiromašene
                    svakodnevne ishrane.
                  </strong>
                </p>
                <p className="content">
                  Za sva pitanja, komentare, savjete i konstruktivne kritike Vam
                  svakodnevno stojimo na usluzi putem kontaktnog formulara ili
                  putem email-a{" "}
                  <a href="mailto:info@immuno-natura.ba">
                    info@immuno-natura.ba
                  </a>
                  .
                </p>
                <p className="content">Radujemo se uspostavljanju kontakta!</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span className="_dec3">
        <img src={shape6} alt="Dec 3" />
      </span>
    </section>
  </Layout>
)

export default Inspiracija
